<!-- src/components/Profile.vue -->

<template src="@/../../../templates/brand/components/templates/user/Profile.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState } from 'vuex'
import Location from '@/models/LocationModel'

export default {
  name: 'Profile',
  created: function () {
    this.getLocations(this.user._id)
    this.newUserLoc = new Location({
      email: this.user.email,
      name: 'new address',
      type: 'personal',
      phone: '',
      contact: `${this.user.profile.first} ${this.user.profile.last}`,
      address: {
        street: '',
        city: '',
        prov: '',
        code: '',
        apt: '',
        country: 'ca'
      }
    })
  },
  data () {
    return {
      newUserLoc: null,
      changeShipping: false,
      changeBilling: false,
      provOptions: [
        { text: 'Alberta', value: 'AB' },
        { text: 'British Columbia', value: 'BC' },
        { text: 'Manitoba', value: 'MB' },
        { text: 'New Brunswick', value: 'NB' },
        { text: 'Newfoundland and Labrador', value: 'NL' },
        { text: 'Nova Scotia', value: 'NS' },
        { text: 'Northwest Territories', value: 'NT' },
        { text: 'Nunavut', value: 'NU' },
        { text: 'Ontario', value: 'ON' },
        { text: 'Prince Edward Island', value: 'PE' },
        { text: 'Quebec', value: 'QC' },
        { text: 'Saskatchewan', value: 'SK' },
        { text: 'Yukon', value: 'YT' }
      ],
      coOptions: [
        { text: 'Canada', value: 'ca' }
        // { text: 'British Columbia', value: 'BC' }
      ]
    }
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user,
      locations: ({ Location }) => Location.locations
    }),
    shippingLocations () {
      return this.locations.filter(l => l.type === 'biz')
    },
    billingLocations () {
      return this.locations.filter(l => l.type !== 'biz')
    },
    notMyLocations: function () {
      return _.filter(
        l => !~this.user.profile.location.indexOf(l._id),
        this.locations
      )
    },
    defaultShipping: function () {
      if (!this.user.profile.shipping) return {address: {}}

      let shippingId = typeof this.user.profile.shipping === 'string' ? this.user.profile.shipping : this.user.profile.shipping._id
      let shipping = this.locations.find(l => l._id === shippingId)
      if (!shipping) return {address: {}}
      return shipping
      // if (_.isEmpty(this.user.profile.shipping)) return this.defaultBilling
      // return this.locations.find(l => l._id === this.user.profile.shipping)
    },
    defaultBilling: function () {
      if (!this.user.profile.billing) return {address: {}}

      let billingId = typeof this.user.profile.billing === 'string' ? this.user.profile.billing : this.user.profile.billing._id
      let billing = this.locations.find(l => l._id === billingId)
      if (!billing) return {address: {}}
      return billing

      // if (_.isEmpty(this.user.profile.billing)) return false
      // return this.locations.find(l => l._id === this.user.profile.billing)
    },
  },
  methods: {
    ...mapActions([
      'profileUpdate',
      'getLocation',
      'getLocations',
      'getCartProducts'
    ]),
    editLoc (loc) {
      console.info(' :: called editLoc', loc)
      const ctx = this // for the callback...
      const location = loc ? loc : this.newUserLoc.getData()
      this.$vex.dialog.open({
        message: 'Add a Location',
        title: 'Add/Edit Location',
        closable: true,
        input: `<label><span class="required">*</span> ${this.$t("forms.name")}</label>
          <input type="text" name="name" value="${location.name || ''}">
          <label><span class="required">*</span> ${this.$t("forms.type")}</label>
          <input type="text" name="type" value="${location.type}">
          <label><span class="required">*</span> ${this.$t("forms.address")}</label>
          <input type="text" name="street" value="${location.address.street}">
          <label>${this.$t("forms.address2")}</label>
          <input type="text" name="apt" value="${location.address.apt || ''}">
          <label><span class="required">*</span> ${this.$t("forms.city")}</label>
          <input type="text" name="city" value="${location.address.city}">
          <label><span class="required">*</span> ${this.$t("forms.province")}</label>
          ${'<select name="prov" value="${location.address.prov}">' +
              this.provOptions.map(o => `<option value="${o.value}">
                ${o.text}
              </option>`).join('') +
            '</select>'}
          <label><span class="required">*</span> ${this.$t("forms.country")}</label>
          ${'<select name="country" value="${location.address.country}">' +
              this.coOptions.map(o => `<option value="${o.value}">
                ${o.text}
              </option>`).join('') +
            '</select>'}
          <label><span class="required">*</span> ${this.$t("forms.postalCode")}</label>
          <input type="text" name="code" value="${location.address.code}">
          <label><span class="required">*</span> ${this.$t("forms.phone")}</label>
          <input type="text" name="phone" value="${location.phone || ''}">
          <label><span class="required">*</span> ${this.$t("forms.email")}</label>
          <input type="email" name="email" value="${location.email}">
          <input type="hidden" name="owner" value="${this.user._id}">
          <input type="hidden" name="contact" value="${location.contact || `${this.user.profile.first} ${this.user.profile.last}`}">`,
        beforeClose: function () {
          console.info(' :: beforeCLose', this)
          if (!this.value) return true // clicked cancel

          // validate this.value
          let valid = true
          this.form.forEach(input => {
            if (['name','email','phone','street','city','code'].includes(input.name)) {
              if (input.value === '') input.classList.add('invalid')
              else input.classList.remove('invalid')
            }
          })

          return valid
        },
        callback: async function (data) {
          if (!data) return

          const newloc = {
            name: data.name,
            type: data.type,
            contact: data.contact,
            email: data.email,
            phone: data.phone,
            owner: data.owner,
            address: {
              street: data.street,
              city: data.city,
              prov: data.prov,
              country: data.country,
              apt: data.apt,
              code: data.code
            }
          }

          if (loc) {
            // update
            // console.info(':: update loc', newloc)
            await ctx.$http.post(`/locations/${loc._id}`, newloc)
          } else {
            // create
            // console.info(':: create loc', newloc)
            await ctx.$http.post(`/locations`, newloc)
          }
          ctx.newUserLoc = new Location({
            email: ctx.user.email,
            name: 'new address',
            type: 'personal',
            phone: '',
            contact: `${ctx.user.profile.first} ${ctx.user.profile.last}`,
            address: {
              street: '',
              city: '',
              prov: '',
              code: '',
              apt: '',
              country: 'ca'
            }
          })
          ctx.getLocations()
        }
      })
    },
    removeLoc: function (id) {
      console.log(' :: request to delete loc', id)
      const inuse = {
        shipping: typeof this.user.profile.shipping === 'string' ? this.user.profile.shipping === id : this.user.profile.shipping._id === id,
        billing: typeof this.user.profile.billing === 'string' ? this.user.profile.billing === id : this.user.profile.billing._id === id
      }
      if (inuse.shipping || inuse.billing) {
        // can't delete active address, tell user to replace it first...
        this.$vex.dialog.alert(`You can't remove an address that is active as a billing or shipping address. Please choose an alternative ${inuse.shipping ? 'shipping' : 'billing'} address then you can remove this one.`)
      } else {
        // console.info(' :: really delete loc', id)
        this.$http.delete(`/locations/${id}`)
        .then(() => {
          this.getLocations()
        })
      }
    },
    setDefaultLocs: function (evt) {
      const _profile = _.assign({}, this.user.profile)
      _profile[evt.target.dataset.ref] = evt.target.value

      this.profileUpdate({ user: this.user, profile: _profile })
      .then(() => {
        if (evt.target.dataset.ref === 'shipping') this.changeShipping = false
        else this.changeBilling = false
      })
    },

  }
}
</script>

<style src="@/../../../templates/brand/components/styles/user/Profile.css" ></style>
